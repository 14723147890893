<template>
  <v-dialog v-model="showDialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="success" class="ma-4">
        {{ $t("devices.registerDevice") }} <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("forms.deviceRegistration.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-stepper v-model="el" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="el > 1" step="1">{{
            $t("forms.deviceRegistration.serialNumber")
          }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="el > 2" step="2">{{
            $t("forms.deviceRegistration.pin")
          }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <PanelDevicesCreateStep1
              ref="step1"
              :loading="loading"
              :error="error"
              :dialog="showDialog"
              @formSubmitted="submitSerialNumber"
              @canceled="closeDialog"
            />
          </v-stepper-content>

          <v-stepper-content step="2">
            <PanelDevicesCreateStep2
              ref="step2"
              :loading="loading"
              :error="error"
              :bonusTimingPoints="bonusTimingPoints"
              :serialNumber="serialNumber"
              :dialog="showDialog"
              :step="el"
              :icon="icon"
              :iconLoading="iconLoading"
              @newPinRequested="requestNewPin"
              @formSubmitted="submitPin"
              @canceled="closeDialog"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";
import PanelDevicesCreateStep1 from "@/components/PanelDevicesCreateStep1";
import PanelDevicesCreateStep2 from "@/components/PanelDevicesCreateStep2";

export default {
  name: "PanelDevicesCreate",
  components: {
    PanelDevicesCreateStep1,
    PanelDevicesCreateStep2
  },
  data() {
    return {
      showDialog: false,
      el: 1,
      loading: false,
      iconLoading: false,
      icon: "mdi-refresh",
      error: null,
      serialNumber: null,
      bonusTimingPoints: 0
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions({
      sendSerialNumber: "devices/sendSerialNumber",
      sendPin: "devices/sendPin",
      fetchBalance: "timingpoints/fetchBalance"
    }),
    closeDialog() {
      this.showDialog = false;
      this.el = 1;
      this.error = null;

      for (let ref in this.$refs) {
        this.$refs[ref].resetForm();
      }
    },
    submitSerialNumber(form) {
      this.error = null;
      this.loading = true;

      const payload = {
        id: form.serialNumber,
        data: {
          userId: this.user.id
        }
      };

      this.sendSerialNumber(payload)
        .then(response => {
          this.el = 2;
          this.serialNumber = form.serialNumber;
          this.bonusTimingPoints = response.data.data[0].bonusTimingPoints;
          this.loading = false;
        })
        .catch(response => {
          switch (response.data.status) {
            case -2000:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.deviceNotFound"
              );
              break;
            case -2002:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.registrationFailed"
              );
              break;
            case -2016:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.deviceOwned"
              );
              break;
            case -2008:
              this.error = `${this.$i18n.t(
                "errors.device.communicationFailed"
              )} ${this.$i18n.t("errors.device.restartHint")}`;
              break;
            default:
              this.error = this.$i18n.t("errors.unexpectedError");
          }
          this.loading = false;
        });
    },
    requestNewPin() {
      this.error = null;
      this.iconLoading = true;

      const payload = {
        id: this.serialNumber,
        data: {
          userId: this.user.id
        }
      };

      this.sendSerialNumber(payload)
        .then(response => {
          this.icon = "mdi-check";
          setTimeout(() => {
            this.icon = "mdi-refresh";
          }, 2000);
          this.iconLoading = false;
        })
        .catch(response => {
          this.icon = "mdi-close";
          setTimeout(() => {
            this.icon = "mdi-refresh";
          }, 2000);
          this.iconLoading = false;
        });
    },
    submitPin(form) {
      this.error = null;
      this.loading = true;

      const payload = {
        id: this.serialNumber,
        data: {
          bonusPoints: form.transferBonusTimingPoints
            ? this.bonusTimingPoints
            : 0,
          code: form.pin,
          userId: this.user.id
        }
      };

      this.sendPin(payload)
        .then(response => {
          this.closeDialog();
          this.fetchBalance(this.user.id).catch(() => {});
          this.loading = false;
        })
        .catch(response => {
          switch (response.data.status) {
            case -2000:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.deviceNotFound"
              );
              break;
            case -2002:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.registrationFailed"
              );
              break;
            case -2003:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.authorizationFailed"
              );
              break;
            case -2008:
              this.error = `${this.$i18n.t(
                "errors.device.communicationFailed"
              )} ${this.$i18n.t("errors.device.restartHint")}`;
              break;
            case -2016:
              this.error = this.$i18n.t(
                "forms.deviceRegistration.errors.deviceOwned"
              );
              break;
            default:
              this.error = this.$i18n.t("errors.unexpectedError");
          }
          this.loading = false;
        });
    }
  }
};
</script>
