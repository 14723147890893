<template>
  <v-form @submit.prevent="submitForm">
    <v-container>
      <v-row dense>
        <v-col cols="12" sm="12">
          <v-text-field
            ref="input"
            :label="$t('forms.deviceRegistration.serialNumber')"
            name="serialNumber"
            prepend-icon="mdi-barcode"
            v-model.trim="form.serialNumber"
            :error-messages="serialNumberErrors"
            :success="shouldAppendValidClass($v.form.serialNumber)"
            autofocus
          ></v-text-field>
        </v-col>
      </v-row>
      <v-alert v-if="error" class="mt-6" dense outlined type="error">
        {{ error }}
      </v-alert>
    </v-container>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn @click="$emit('canceled')" text :disabled="loading">
        {{ $t("forms.deviceRegistration.cancel") }}
      </v-btn>

      <v-btn
        type="submit"
        color="primary"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("forms.deviceRegistration.continue") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
/* eslint-disable */

import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "PanelDevicesCreateStep1",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: {
        serialNumber: null,
      }
    };
  },
  validations: {
    form: {
      serialNumber: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9)
      }
    },
  },
  computed: {
    serialNumberErrors() {
      const errors = [];
      if (!this.$v.form.serialNumber.$dirty) return errors;
      !this.$v.form.serialNumber.required && errors.push(this.$i18n.t("forms.deviceRegistration.errors.serialNumber.required"));
      !this.$v.form.serialNumber.maxLength && errors.push(this.$i18n.t("forms.deviceRegistration.errors.serialNumber.length"));
      !this.$v.form.serialNumber.minLength && errors.push(this.$i18n.t("forms.deviceRegistration.errors.serialNumber.length"));
      !this.$v.form.serialNumber.numeric && errors.push(this.$i18n.t("forms.deviceRegistration.errors.serialNumber.numeric"));
      return errors;
    }
  },
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog) {
        this.$nextTick(() => this.$refs.input.focus());
      }
    }
  },
  methods: {
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    resetForm() {
      this.form.serialNumber = null;
      this.$v.form.$reset();
    },
    submitForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("formSubmitted", this.form);
      }
    }
  },
  mounted() {
    if (this.dialog) {
      this.$nextTick(() => this.$refs.input.focus());
    }
  }
};
</script>
