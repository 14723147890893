<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-avatar v-on="on" size="15" :color="lastUpdatedColor" />
    </template>
    {{ $d(item.deviceComponents.MobileInfo.lastUpdated, "shortDateTime") }}
  </v-tooltip>
</template>

<script>
import moment from "moment";

export default {
  name: "MobileStatusIndicator",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      now: Date.now()
    };
  },
  computed: {
    lastUpdatedColor() {
      const diff_ms = moment(this.now).diff(
        moment(this.item.deviceComponents.MobileInfo.lastUpdated)
      );
      const duration_ms = moment.duration(diff_ms);
      const duration_m = duration_ms.asMinutes();

      if (duration_m < 30) {
        return "success";
      } else if (duration_m < 35) {
        return "warning";
      }

      return "error";
    }
  },
  methods: {
    setNowReference() {
      this.now = Date.now();
    }
  },
  created() {
    this.setNowReference();
    setInterval(this.setNowReference, 10000);
  },
  destroyed() {
    clearInterval(this.setNowReference);
  }
};
</script>
