<template>
  <v-form @submit.prevent="submitForm">
    <v-container>
      <v-row dense>
        <v-col cols="10">
          <v-text-field
            ref="input"
            :label="$t('forms.deviceRegistration.pin')"
            name="pin"
            prepend-icon="mdi-lock"
            :value="form.pin"
            @input="value => (form.pin = value.toUpperCase())"
            :maxlength="5"
            counter
            :error-messages="pinErrors"
            :success="shouldAppendValidClass($v.form.pin)"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-btn
            :loading="iconLoading"
            depressed
            small
            fab
            :color="iconColor"
            @click="requestNewPin"
          >
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="bonusTimingPoints > 0" cols="12">
          <v-switch
            :label="
              $t('forms.deviceRegistration.transferBonusTimingPoints', {
                number: bonusTimingPoints
              })
            "
            name="transfer"
            v-model="form.transferBonusTimingPoints"
          ></v-switch>
        </v-col>
      </v-row>
      <v-alert v-if="error" class="mt-6" dense outlined type="error">
        {{ error }}
      </v-alert>
    </v-container>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn @click="$emit('canceled')" text :disabled="loading">
        {{ $t("forms.deviceRegistration.cancel") }}
      </v-btn>

      <v-btn
        type="submit"
        color="primary"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("forms.deviceRegistration.continue") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
/* eslint-disable */

import { required } from "vuelidate/lib/validators";

export default {
  name: "PanelDevicesCreateStep2",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    bonusTimingPoints: {
      type: Number,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      default: "mdi-refresh"
    },
    iconLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        pin: null,
        transferBonusTimingPoints: false
      }
    };
  },
  validations: {
    form: {
      pin: { required }
    },
  },
  computed: {
    pinErrors() {
      const errors = [];
      if (!this.$v.form.pin.$dirty) return errors;
      !this.$v.form.pin.required && errors.push(this.$i18n.t("forms.deviceRegistration.errors.pin.required"));
      return errors;
    },
    iconColor() {
      if (this.icon === "mdi-check") {
        return "success";
      } else if (this.icon === "mdi-close") {
        return "error";
      }
    }
  },
  watch: {
    dialog: function (newDialog, oldDialog) {
      if (newDialog) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    step: function (newStep, oldStop) {
      if (newStep === 2) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    }
  },
  methods: {
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    requestNewPin() {
      this.$emit("newPinRequested");

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    resetForm() {
      this.form.pin = null;
      this.$v.form.$reset();
    },
    submitForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("formSubmitted", this.form);
      }
    }
  },
  mounted() {
    if (this.dialog) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  }
};
</script>
