var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('PanelDevicesCreate'),_c('v-card-title',[_vm._v(_vm._s(_vm.$i18n.t("devices.title")))]),(_vm.devices)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.indexedDevices,"sort-by":"id","mobile-breakpoint":"0","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.deviceComponents.MobileInfo.lastUpdated",fn:function({ item }){return [_c('MobileStatusIndicator',{attrs:{"item":item}})]}},{key:"item.id",fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'DevicesEdit', params: { id: item.id } },"exact":""}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.deviceComponents.DeviceInfo.firmwareVersion",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-baseline"},[_vm._v(" "+_vm._s(item.deviceComponents.DeviceInfo.firmwareVersion)+" "),(
            _vm.isLatestFirmwareVersion(
              item.type,
              item.deviceComponents.DeviceInfo.firmwareVersion
            )
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"success","small":"","right":""}},on),[_vm._v("mdi-check")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("devices.hints.latestFirmware"))+" ")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"error","small":"","right":""}},on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("devices.hints.updateFirmware"))+" ")])],1)]}},{key:"item.deviceComponents.MobileInfo.signalStrength",fn:function({ item }){return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.signalStrengthIcon(item.deviceComponents.MobileInfo.signalStrength))+" ")])]}},{key:"item.deviceComponents.MobileInfo.operatorName",fn:function({ item }){return [(
          item.deviceComponents.MobileInfo.operatorName &&
            item.deviceComponents.MobileInfo.radioAccessTechnology
        )?_c('span',[_vm._v(" "+_vm._s(`${item.deviceComponents.MobileInfo.operatorName} (${item.deviceComponents.MobileInfo.radioAccessTechnology})`)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"top":"","open-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"to":{ name: 'DevicesEdit', params: { id: item.id } },"icon":"","exact":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("hints.editDevice"))+" ")]),_c('v-tooltip',{attrs:{"top":"","open-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loadingStatus(item.index),"icon":""},on:{"click":function($event){return _vm.refreshDevice(item.index, item)}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("hints.refreshDevice"))+" ")]),_c('v-tooltip',{attrs:{"top":"","open-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.removeDevice(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("hints.deleteDevice"))+" ")])],1)]}}],null,false,115783465)}):_c('v-card-text',[_vm._v(" No registered devices. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }