<template>
  <v-card>
    <PanelDevicesCreate />
    <v-card-title>{{ $i18n.t("devices.title") }}</v-card-title>
    <v-data-table
      v-if="devices"
      :headers="headers"
      :items="indexedDevices"
      sort-by="id"
      mobile-breakpoint="0"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.deviceComponents.MobileInfo.lastUpdated="{ item }">
        <MobileStatusIndicator :item="item" />
      </template>

      <template v-slot:item.id="{ item }">
        <router-link
          :to="{ name: 'DevicesEdit', params: { id: item.id } }"
          exact
        >
          {{ item.id }}
        </router-link>
      </template>

      <template
        v-slot:item.deviceComponents.DeviceInfo.firmwareVersion="{ item }"
      >
        <div class="d-flex align-baseline">
          {{ item.deviceComponents.DeviceInfo.firmwareVersion }}
          <v-tooltip
            v-if="
              isLatestFirmwareVersion(
                item.type,
                item.deviceComponents.DeviceInfo.firmwareVersion
              )
            "
            top
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="success" small right>mdi-check</v-icon>
            </template>
            {{ $t("devices.hints.latestFirmware") }}
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error" small right
                >mdi-alert-circle-outline</v-icon
              >
            </template>
            {{ $t("devices.hints.updateFirmware") }}
          </v-tooltip>
        </div>
      </template>

      <template
        v-slot:item.deviceComponents.MobileInfo.signalStrength="{ item }"
      >
        <v-icon>
          {{
            signalStrengthIcon(item.deviceComponents.MobileInfo.signalStrength)
          }}
        </v-icon>
      </template>

      <template v-slot:item.deviceComponents.MobileInfo.operatorName="{ item }">
        <span
          v-if="
            item.deviceComponents.MobileInfo.operatorName &&
              item.deviceComponents.MobileInfo.radioAccessTechnology
          "
        >
          {{
            `${item.deviceComponents.MobileInfo.operatorName} (${item.deviceComponents.MobileInfo.radioAccessTechnology})`
          }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :to="{ name: 'DevicesEdit', params: { id: item.id } }"
                icon
                exact
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.editDevice") }}
          </v-tooltip>

          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="refreshDevice(item.index, item)"
                :loading="loadingStatus(item.index)"
                icon
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.refreshDevice") }}
          </v-tooltip>

          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="removeDevice(item)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.deleteDevice") }}
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-card-text v-else>
      No registered devices.
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */

import Vue from "vue";
import { mapState, mapActions } from "vuex";
import PanelDevicesCreate from "@/components/PanelDevicesCreate";
import MobileStatusIndicator from "@/components/MobileStatusIndicator";
import deviceTypes from "@/utils/deviceTypes";

export default {
  name: "PanelDevices",
  components: {
    PanelDevicesCreate,
    MobileStatusIndicator
  },
  data() {
    return {
      loading: []
    };
  },
  computed: {
    ...mapState({
      devices: state => state.devices.items,
      user: state => state.user.user
    }),
    headers() {
      return [
        {
          value: "deviceComponents.MobileInfo.lastUpdated",
          align: "right",
          sortable: false
        },
        {
          text: this.$i18n.t("devices.table.id"),
          value: "id"
        },
        {
          text: this.$i18n.t("devices.table.name"),
          value: "deviceComponents.AppSettings.name"
        },
        {
          text: this.$i18n.t("devices.table.firmware"),
          value: "deviceComponents.DeviceInfo.firmwareVersion"
        },
        {
          text: this.$i18n.t("devices.table.operator"),
          value: "deviceComponents.MobileInfo.operatorName"
        },
        {
          text: this.$i18n.t("devices.table.signalStrength"),
          value: "deviceComponents.MobileInfo.signalStrength"
        },
        {
          value: "actions",
          sortable: false,
          width: "1%"
        }
      ];
    },
    indexedDevices() {
      return this.devices.map((device, index) => ({
        index,
        ...device
      }));
    }
  },
  methods: {
    ...mapActions({
      deregisterItem: "devices/deregisterItem",
      fetchItemStatus: "devices/fetchItemStatus",
      fetchBalance: "timingpoints/fetchBalance"
    }),
    signalStrengthIcon(signalStrength) {
      if (signalStrength > 66) {
        return "mdi-signal-cellular-3";
      } else if (signalStrength > 33) {
        return "mdi-signal-cellular-2";
      } else if (signalStrength > 0) {
        return "mdi-signal-cellular-1";
      }

      return "mdi-signal-cellular-outline";
    },
    loadingStatus(index) {
      return this.loading[index];
    },
    removeDevice(device) {
      confirm(this.$i18n.t("devices.confirmation", { device: device.id })) &&
        this.deregisterItem(device.id)
          .then(() => this.fetchBalance(this.user.id))
          .catch(() => {});
    },
    refreshDevice(index, device) {
      Vue.set(this.loading, index, true);

      this.fetchItemStatus(device.id)
        .then(() => {
          Vue.set(this.loading, index, false);
        })
        .catch(() => {
          Vue.set(this.loading, index, false);
        });
    },
    isLatestFirmwareVersion(deviceType, firmwareVersion) {
      let latestFirmwareVersion = deviceTypes[
        deviceType
      ].latestFirmwareVersion.replace(".", "");
      firmwareVersion = firmwareVersion.replace(".", "");

      return (
        parseInt(firmwareVersion, 16) >= parseInt(latestFirmwareVersion, 16)
      );
    }
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
</style>
